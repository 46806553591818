// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bilder-dokument-js": () => import("./../src/pages/bilder-dokument.js" /* webpackChunkName: "component---src-pages-bilder-dokument-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lagenheter-js": () => import("./../src/pages/lagenheter.js" /* webpackChunkName: "component---src-pages-lagenheter-js" */),
  "component---src-pages-omradet-js": () => import("./../src/pages/omradet.js" /* webpackChunkName: "component---src-pages-omradet-js" */),
  "component---src-pages-radhus-js": () => import("./../src/pages/radhus.js" /* webpackChunkName: "component---src-pages-radhus-js" */),
  "component---src-pages-tack-js": () => import("./../src/pages/tack.js" /* webpackChunkName: "component---src-pages-tack-js" */),
  "component---src-pages-tack-vip-js": () => import("./../src/pages/tack-vip.js" /* webpackChunkName: "component---src-pages-tack-vip-js" */)
}

