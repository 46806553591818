import styled from "styled-components"

export const H2 = styled.h2`
  font-size: 90px;
  line-height: 85px;
  text-wrap: none;
  display: inline flow-root;
  width: 100%;
  max-width: 800px;
  white-space: nowrap;
  list-style: none;
  font-weight: 500;

  &.alignRight {
    text-align: right;
    justify-self: right;
  }

  @media only screen and (max-width: 1080px) {
    font-size: 90px;
    line-height: 95px;

    &.alignRight {
      text-align: left;
      justify-self: left;
    }
  }

  @media only screen and (max-width: 800px) {
    margin-bottom: 10px;
    
    &.mobileTranslate {
      position: absolute;
      top: 0;
      transform: translateY(calc(-100% - 20px));
      max-width: 300px;
    }
  }
`
